.command-line {
  padding: 1rem;
  margin-left: 1rem;
  border-left: 2px solid green;
}

.command {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
}

.command .command-start-text {
  font-weight: 700;
}

.result {
  color: aqua;
  margin-left: 2rem;
}
